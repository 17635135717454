import { computed, inject, Injectable, signal } from '@angular/core';
import { lastValueFrom, mergeMap, of, throwError } from 'rxjs';

import { IPopupModel } from '@mwe/models';

import { HttpResponse } from '@angular/common/http';
import { ApiService } from '@berater/service/api/api.service';
import { IkpPopupService } from '@berater/service/popup/ikp-popup.service';
import { API_ROUTE_SEARCH_BERATER, API_ROUTE_GET_BERATER, getApiUrl } from '@berater/app.constants';
import { IBerater, ISearchKundenberaterRequest } from '@berater/model/berater-http.model';
import { Berater } from '@berater/model/berater.model';

@Injectable({
  providedIn: 'root',
})
export class BeraterService extends ApiService {
  private readonly popupService = inject(IkpPopupService);
  berater = signal<Berater[]>([]);

  page = signal<number>(1);
  noContent = computed(() => this.berater().length === 0);

  loadBerater(requestBody: ISearchKundenberaterRequest): Promise<IBerater[]> {
    return lastValueFrom(
      this.httpClient.post<IBerater[]>(getApiUrl(this.apiUrl, API_ROUTE_SEARCH_BERATER), requestBody, { observe: 'response' }),
    )
      .then((response: HttpResponse<IBerater[]>) => {
        if (response && response.status === 204) {
          this.berater.set([]);
          return [];
        }

        this.berater.set(response.body);
        return response.body;
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching berater:');

        this.handleError();

        return [];
      });
  }

  getBeraterInfoAboutCurrentUser(): Promise<IBerater> {
    return lastValueFrom(
      this.httpClient.get<IBerater>(getApiUrl(this.apiUrl, API_ROUTE_GET_BERATER), { observe: 'response' }).pipe(
        mergeMap(response => {
          if (response && response.status !== 200) {
            return throwError(() => new Error('Get berater request failed.'));
          } else {
            return of(response.body);
          }
        }),
      ),
    );
  }

  private handleError(): void {
    const errorPopupModel: IPopupModel = {
      id: 'download-error',
      titleKey: 'global.errors.berater.title',
      messageKey: 'global.errors.defaultErrorMessage',
      showSubmitButton: true,
      submitButtonKey: 'OK',
      showCancelButton: false,
      iconColorClass: 'red',
      iconTypeClass: 'fa-bell',
    };
    this.popupService.handleGenericPopup(errorPopupModel);
  }
}
